<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-form>
              <div class="demo-collapse">
                <el-collapse v-model="collapseFlag">
                  <el-collapse-item class="ml-4" :title="$t('Group setting')">
                    <b-row>
                      <b-col><div class="form-control-label float-right mt-2">{{ $t('Setup Current Quantity For All') }}</div></b-col>
                      <b-col><b-input class="form-control" v-model="defaultQty" /></b-col>
                      <b-col><b-button variant="danger" @click="update_all_qty">{{ $t('Update') }}</b-button></b-col>
                      <b-col><div class="form-control-label float-right mt-2">{{ $t('Setup Minimum Quantity For All') }}</div></b-col>
                      <b-col><b-input class="form-control" v-model="defaultMin" /></b-col>
                      <b-col><b-button variant="danger" @click="update_all_min">{{ $t('Update') }}</b-button></b-col>
                    </b-row>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <b-row>
                <b-col>
                  <base-input :label="$t('Store')">
                    <select
                      class="form-control"
                      v-model="curLocationID"
                      @change="changed_store"
                    >
                      <option
                        v-for="st in locationlist"
                        :key="st.LocationID"
                        :value="st.LocationID"
                      >
                        {{ st.LocationID }}-{{ st.LocationName }}-{{
                          st.Address1
                        }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col>
                  <base-input :label="$t('Department')">
                    <select
                      class="form-control"
                      v-model="curDepartmentIDn"
                      @change="changed_department"
                    >
                      <option value=0></option>
                      <option
                        v-for="dp in departmentlist"
                        :key="dp.IDn"
                        :value="dp.IDn"
                      >
                        {{ dp.IDn }}-{{ dp.Name1 }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col>
                  <base-input :label="$t('Category')">
                    <select class="form-control" v-model="curCategoryIDn">
                      <option value=0></option>
                      <option
                        v-for="dp in categorylist"
                        :key="dp.IDn"
                        :value="dp.IDn"
                      >
                        {{ dp.IDn }}-{{ dp.Name1 }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col>
                  <base-input :label="$t('Name')">
                    <b-input class="form-control" v-model="curName" />
                  </base-input>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    @click="submit_search"
                    class="mt-4"
                    >{{ $t("Submit") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
            <p class="p-3" v-if="productlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="productlist"
              >
                <el-table-column :label="$t('Store')" prop="LocationID">
                </el-table-column>
                <el-table-column :label="$t('Name') + '1'" prop="Name1">
                </el-table-column>
                <el-table-column :label="$t('Name') + '2'" prop="Name2">
                </el-table-column>
                <!-- el-table-column :label="$t('Quantity')" prop="Quantity">
                  <template v-slot="{ row }">
                    <InputSubmit :record="row" :myvalue="row.Quantity" @inputsubmit="set_quantity"></InputSubmit>
                  </template>
                </el-table-column -->
                <el-table-column :label="$t('Soldout')" v-if="!HasInventory">
                  <template v-slot="{ row }">
                    <div v-if="row.Soldout">
                      <span class="yesno">{{ $t("Yes") }}</span>
                    </div>
                    <div v-else>
                      <span class="yesno">{{ $t("No") }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Setting')" v-if="!HasInventory && hasUtilityAuth('SoldoutEdit')">
                  <template v-slot="{ row }">
                    <div v-if="row.Soldout">
                      <el-button
                        type="success"
                        @click="set_soldout(0, row.LocationID, row.ProductIDn)"
                        v-acl:SoldoutEdit.enable.show
                        >{{ $t("Reset") }}</el-button
                      >
                    </div>
                    <div v-else>
                      <el-button
                        type="danger"
                        @click="set_soldout(1, row.LocationID, row.ProductIDn)"
                        v-acl:SoldoutEdit.enable.show
                        >{{ $t("Soldout") }}</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Quantity')" v-if="HasInventory">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.Qty" type="number" step="0.01" @change="update_row(scope.row)" size="mini" />
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Min Qty')" v-if="HasInventory">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.MinThreshold" type="number" step="0.01" @change="update_row(scope.row)" size="mini" />
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
            <b-card-footer
              v-if="totalProduct"
              class="py-4 d-flex justify-content-end"
            >
              <base-pagination
                v-model="currentPage"
                :per-page="parseInt(perPage)"
                :total="parseInt(totalProduct)"
                @change="page_changed"
              ></base-pagination>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Collapse, CollapseItem, Input } from "element-ui";
import { getStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Input.name]: Input,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      curLocationID: "",
      curDepartmentIDn: 0,
      curCategoryIDn: 0,
      curName: "",

      currentPage: 0,
      perPage: 20,
      totalProduct: 0,

      HasInventory: 0,

      collapseFlag: 0,
      defaultQty: 1000,
      defaultMin: 0,

      locationlist: null,
      departmentlist: null,
      categorylist: null,
      productlist: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    page_changed(v) {
      this.currentPage = parseInt(v);
      this.get_data();
    },
    async changed_store() {
      this.curDepartmentIDn = 0;
      this.curCategoryIDn = 0;
      this.curName = "";
    },
    async changed_department() {
      this.curCategoryIDn = 0;
      this.curName = "";
      this.categorylist = null;
      for (var i = 0; i < this.departmentlist.length; i++) {
        if (this.departmentlist[i].IDn == this.curDepartmentIDn) {
          this.categorylist = this.departmentlist[i].category;
          break;
        }
      }
    },
    async submit_search() {
      this.currentPage = 1;
      this.totalProduct = 0;
      this.get_data();
    },
    async get_data() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("LocationID", this.curLocationID);
      bodyFormData.append("DepartmentIDn", this.curDepartmentIDn);
      bodyFormData.append("CategoryIDn", this.curCategoryIDn);
      bodyFormData.append("Name", this.curName);
      bodyFormData.append("currentPage", this.currentPage);
      bodyFormData.append("perPage", this.perPage);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.curLocationID = rt.data.LocationID;
              if (!that.locationlist) {
                that.locationlist = rt.data.locations;
                if (that.locationlist.length <= 0) {
                  that.$router.push("/store");
                  return;
                }
              }
              if (!that.departmentlist) {
                that.departmentlist = rt.data.departments;
              }
              that.productlist = rt.data.products;
              that.totalProduct = rt.data.totalProduct;
              that.HasInventory = parseInt(rt.data.HasInventory);
              that.currentPage = parseInt(rt.data.currentPage);
              that.perPage = parseInt(rt.data.perPage);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_row(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("LocationID", row.LocationID);
      bodyFormData.append("ProductIDn", row.ProductIDn);
      bodyFormData.append("MinThreshold", row.MinThreshold);
      bodyFormData.append("Qty", row.Qty);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store_product/update_inventory",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              ;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_all(name, data) {
      // Clear current data
      this.curName = "";
      this.curCategoryIDn = 0;
      this.curDepartmentIDn = 0;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.curLocationID);
      bodyFormData.append(name, data);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store_product/update_all_inventory",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.curLocationID = rt.data.LocationID;
              that.productlist = rt.data.products;
              that.totalProduct = rt.data.totalProduct;
              that.currentPage = parseInt(rt.data.currentPage);
              that.perPage = parseInt(rt.data.perPage);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    update_all_min() {
      this.update_all("MinThreshold", this.defaultMin);
    },
    update_all_qty() {
      this.update_all("Qty", this.defaultQty);
    },
    async set_soldout(soldout, LocationID, ProductIDn) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("soldout", soldout);
      bodyFormData.append("LocationID", LocationID);
      bodyFormData.append("ProductIDn", ProductIDn);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store_product/soldout",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async set_quantity(quantity, row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("quantity", quantity);
      bodyFormData.append("LocationID", row.LocationID);
      bodyFormData.append("ProductIDn", row.ProductIDn);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store_product/quantity",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.search-button {
  margin-top: 2rem;
}
.yesno {
  font-weight: bold;
  padding-right: 1.5rem;
}
</style>
